<template>
  <div id="qll-page">
    <TitleHelper :title="'QLL question viewer'"></TitleHelper>
    <div class="display">
      <div v-for="(question, n) in questionDetails" :key="'q'+n" class="question" :class="{'problem': logged.includes(question._id)}" style="position: relative;">
        <div class="question-text">{{question.question}}</div>
        <div class="answer-text">{{question.answer}}</div>
        <div class="provenance">
          {{question.purpose}}, 
          round {{question.round_number}}, 
          question {{question.question_number}}, 
          correct as of {{new Date(question.dateCorrect).getDate()}} {{monthNames[new Date(question.dateCorrect).getMonth()+1]}} {{new Date(question.dateCorrect).getFullYear()}}
          <button @click="flagQuestion(question._id)" :class="{'hidden': logged.includes(question._id)}" class="report-button">Does this question look wrong?</button>
          <span v-if="logged.includes(question._id)" class="reported">This question has been reported.<button class="unreport-button" @click="unflagQuestion(question._id)" >Unreport</button></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const config = require("../../assets/config");
import TitleHelper from '../../helpers/vueTitleHelper.vue';

export default {
  name: "QLL",
  beforeCreate: function() {
    document.body.className = 'qll';
  },
  data() {
    return {
        values: config,
        errorValue: null,
        questionDetails: null,
        logged: [],
        monthNames: {
         1: 'January',
         2: 'February',
         3: 'March',
         4: 'April',
         5: 'May',
         6: 'June',
         7: 'July',
         8: 'August',
         9: 'September',
         10: 'October',
         11: 'November',
         12: 'December'
        },
    };
  },
  mounted() {
    this.getQuestion();
  },
  components: {
    TitleHelper,
  },
  methods: {
    getQuestion: function() {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = {  };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/qll/random/10";
      axios
        .get(url,body,config)
        .then(response => {
            this.questionDetails = response["data"];
            for (var q of this.questionDetails) {
              if (q.status == 'problem') {
                this.logged.push(q._id);
              }
            }
        })
        .catch(error => {
            this.errorValue = error;
        })
    },
    flagQuestion: function(id) {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      }
      var body = { 'item': id };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/qll/report";
      axios
        .put(url,body,config)
        .then(response => {
          this.logged.push(id);
        })
        .catch(error => {
          this.errorValue = error;
        })
    },
    unflagQuestion: function(id) {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      }
      var body = { 'item': id };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/qll/unreport";
      axios
        .put(url,body,config)
        .then(response => {
          this.logged = this.logged.filter((p) => { return p != id });
        })
        .catch(error => {
          this.errorValue = error;
        })
    }
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#qll-page {
  padding: 8px;
  min-height: calc(100vh - 16px);
  background-color: black;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3333dd;
}

body {
  margin: 0px;
}

div.question {
  border-radius: 5px;
  margin: 5px;
  border: 1px solid #118;
  text-align: left;
  padding: 10px;
}

div.question.problem {
  background-color: #711;
  border: 1px solid #911;
  color: #400;
}

div.question-text {
  font-weight: bold;
}

div.answer-text {
  font-weight: normal;
  text-align: right;
  color: #11a;
}

div.question.problem div.answer-text {
  font-weight: normal;
  text-align: right;
  color: #100;
}

div.provenance {
  font-size: 8px;
  color: #ccc;
}

button.report-button {
  position: relative;
  font-family: 'Avenir';
  background-color: #11a;
  color: #66c;
  border: none;
  border-radius: 5px;
  left: 10px;
}

button.hidden {
  display: none;
}

span.reported {
  color: red;
  left: 5px;
  position: relative;
}

button.unreport-button {
  position: relative;
  font-family: 'Avenir';
  background-color: #a22;
  color: #400;
  border: none;
  border-radius: 5px;
  left: 10px;
}

</style>

